<template>
  <div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="items"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
      >
        <template slot="name" slot-scope="name, record">
          <div>
            <div v-if="isAdmin">
              <router-link
                :to="{ path: '/laboratorium/details/' + record.id }"
                class="kit__utils__link font-size-16"
                >{{ record.name }}</router-link
              >
            </div>
            <div v-if="!isAdmin">{{ record.name }}</div>
            <!-- <div class="text-gray-4">{{record.tag? record.tag: '-'}}</div> -->
          </div>
        </template>
        <template slot="createdAt" slot-scope="createdAt">
          <div>
            <div>
              {{ createdAt ? moment(createdAt).format('DD MMM YYYY') : '-' }}
            </div>
          </div>
        </template>
        <template slot="address" slot-scope="address">
          <div>
            <div>{{ address ? address : '-' }}</div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { getAll } from '@/services/axios/api/laboratorium'
import moment from 'moment'
const columns = [
  {
    title: 'Nama Laboratorium',
    dataIndex: 'name',
    className: 'bg-transparent text-gray-6',
    fixed: 'left',
    scopedSlots: { customRender: 'name' },
    sorter: (a, b) => {
      return a.name.localeCompare(b.name)
    },
  },
  {
    title: 'Tanggal Bergabung',
    dataIndex: 'createdAt',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'createdAt' },
    sorter: (a, b) => {
      return a.createdAt.localeCompare(b.createdAt)
    },
  },
  {
    title: 'Alamat Laboratorium',
    dataIndex: 'address',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'address' },
    sorter: (a, b) => {
      return a.address.localeCompare(b.address)
    },
  },
]

export default {
  name: 'TabelLaboratorium',
  data: function () {
    return {
      columns,
      items: [],
      isLoading: true,
      isAdmin: false,
    }
  },
  created() {
    this.getListData()
    this.getRole()
  },
  methods: {
    moment,
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getListData() {
      const res = await getAll()
      this.isLoading = false
      this.items = res
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
